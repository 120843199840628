<template>
  <Spinner :loading="packingList.loading" />
  <div class="invoice">
    <div class="inv-inner">
      <!-- Invoice Header -->
      <div class="inv-header">
        <Icon class="h-6 w-auto" name="logo-package-list" />
        <div>
          <div class="inv-number">
            <span>Packing List</span>
            <p>{{ packingList.data.data?.order.invoice_number }}</p>
          </div>
          <div class="inv-number" v-if="packingList.data.data?.order.reference_number">
            <span>Reference #</span>
            <p>{{ packingList.data.data?.order.reference_number }}</p>
          </div>
        </div>
      </div>
      <div class="inv-icon">
        <span><Icon name="package-filled"/></span>
      </div>

      <!-- Invoice Content -->
      <div class="inv-details">
        <div class="address">
          <div class="address-grid">
            <div>
              <span class="small">Ship From</span>
              <p>Medusa Distribution</p>
              <span>
                9 Woodland Rd. Suite D<br />
                Roseland, NJ 07068
              </span>
            </div>
            <div>
              <span class="small">Ship To</span>
              <p>{{ packingList.data.data?.shippingAddress.company_name }}</p>
              <span>
                {{ packingList.data.data?.shippingAddress.address1 }}<br />
                {{ packingList.data.data?.shippingAddress.address2
                }}<br v-if="packingList.data.data?.shippingAddress.address2" />
                {{ packingList.data.data?.shippingAddress.city }},
                {{ packingList.data.data?.shippingAddress.state }}
                {{ packingList.data.data?.shippingAddress.zip_code }}
              </span>
            </div>
          </div>
          <div>
            <span class="small">Representative</span>
            <p>
              {{ packingList.data.data?.order?.assignee.name +' '+ packingList.data.data?.order?.assignee.last_name }}
            </p>
            <span>
              <div>{{ packingList.data.data ? packingList.data.data?.order?.assignee.email : '' }}</div>
              <div>{{ packingList.data.data ? packingList.data.data?.order?.assignee.phone_number : '' }}</div>
            </span>
          </div>
        </div>
        <div class="details shipment-info">
          <div class="flex space-x-16">
            <div class="flex-shrink-0">
              <span class="text-xs uppercase text-theme-body"
                >Total Units Shipped</span
              >
              <p class="text-2xl font-medium text-black mt-1">
                {{ packingList.data.data?.totalQuantity }}/{{
                  packingList.data.data?.order.OrderLineItemsQuantity
                }}
              </p>
            </div>
            <div class="flex-shrink-0">
              <span class="text-xs uppercase text-theme-body">SHIPMENTS</span>
              <p class="text-2xl font-medium text-black mt-1">
                {{
                  packingList.data.data
                    ? Object.keys(packingList.data.data.ships).length
                    : 1
                }}
              </p>
            </div>
          </div>
          <div class="pt-12 flex items-start justify-end hide-on-print">
            <button
              class="inline-flex items-center space-x-1 bg-theme-secondary text-white px-4 py-1.5 rounded leading-none"
              @click="downloadInvoice"
            >
              <DownloadIcon class="w-3.5 h-3.5" />
              <span>Download</span>
            </button>
          </div>
        </div>
      </div>

      <!-- List Items -->
      <div class="inv-list">
        <template v-for="(s, i) in packingList.data.data?.ships" :key="i">
          <div class="inv-list-header mt-2">
            <div
              class="info flex items-center space-x-3 text-base normal-case text-theme-dark"
            >
              <div class="w-12 flex items-start">
                <img
                  src="/images/no-image.png"
                  alt="Visa"
                  class="max-w-full h-8"
                />
              </div>
              <p>
                {{ s[0] ? s[0].ship_via : null }} - Shipped on
                {{ s[0] ? moment(s[0].ship_date).format("MMM Do YYYY") : null }}
                <span class="text-md">({{ totalItems(s) }} units)</span>
              </p>
            </div>
            <div class="total-items text-theme-body">Qty</div>
          </div>
          <div class="inv-list-items shipment-list">
            <div class="inv-list-item" v-for="l in s" :key="l.variant_sku">
              <template v-if="!['UN3480', 'UN3481'].includes(l.variant_name)">
                <div class="item-info">
                  <div class="item-check">
                    <span></span>
                  </div>
                  <div class="prod-info">
                    <img
                      :src="
                        l.variant_image == null
                          ? l.product_image
                          : l.variant_image
                      "
                      :alt="l.variant_name"
                    />
                    <div class="prod-name">
                      <p>{{ l.product_name }} - {{ l.variant_name }}</p>
                      <span>{{ l.variant_sku }}</span>
                    </div>
                  </div>
                </div>
                <div class="total-items">{{ parseInt(l.ship_qty) }} Items</div>
              </template>
            </div>
          </div>
        </template>
      </div>

      <!-- Invoice Footer -->
      <div class="inv-footer">
        <span
          >Medusa Distribution LLC 9 Woodland Rd., Suite D,<br />
          Roseland, NJ, 07068, US
        </span>
        <div class="info">
          <span>Email sales@medusadistribution.com</span>
          <span>Phone 973-808-1101</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mymixin from "@/modules/mixins";
import Account from "@/modules/Account";
import { DownloadIcon } from "@heroicons/vue/outline";

export default {
  name: "Packing List",
  mixins: [Mymixin],
  components: {
    DownloadIcon,
  },
  methods: {
    downloadInvoice() {
      window.print();
    },
    totalItems(data) {
      let t = 0;
      data.forEach((e) => {
        if (!["UN3480", "UN3481"].includes(e.variant_name)) {
          t += parseInt(e.ship_qty);
        }
      });
      return t;
    },
    moment: function(date) {
      return moment(date);
    },
  },
  setup() {
    const { packingList, PackingList } = Account();
    PackingList();
    return {
      packingList,
    };
  },
};
</script>

<style>
@page {
  size: A4;
  margin: 15mm 0;
}

@page :first {
  margin-top: 0in;
}

@media print {
  html,
  body {
    @apply p-0 m-0 bg-white overflow-visible w-full;
    font-size: 11px;
    -webkit-print-color-adjust: exact;
    height: 282mm;
  }
  .hide-on-print {
    visibility: hidden;
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.invoice {
  @apply w-full h-full p-8 bg-theme-bg bg-opacity-30;

  @media print {
    @apply bg-white;
  }

  .inv-inner {
    @apply max-w-5xl mx-auto bg-white shadow-md;

    @media print {
      @apply max-w-full shadow-none;
      page-break-after: always;
    }
  }
  .inv-header {
    @apply w-full px-10 py-8 flex items-start  justify-between;
    background: #f8f8f8;

    .inv-number {
      @apply flex items-center text-xs font-medium space-x-3;
      color: rgba(72, 90, 93, 0.54);

      span {
        min-width: 75px;
      }

      p {
        @apply text-sm;
        color: #485a5d;
      }
    }
  }

  .inv-icon {
    @apply w-full flex items-center justify-center pb-10;

    span {
      @apply flex items-center justify-center w-14 h-14 border  rounded-full flex-shrink-0 bg-white -mt-7;
      border-color: #dbdbdb;

      svg {
        @apply w-9 h-9;
      }
    }
  }

  .inv-details {
    @apply flex items-start justify-between text-sm text-theme-body px-10 pb-8 leading-normal;

    .address-grid {
      @apply grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-6;

      @media print {
        @apply grid grid-cols-2 gap-x-12 gap-y-6;
      }
    }

    .small {
      @apply inline-block text-xs text-theme-body uppercase font-normal mb-1.5;
    }

    .address {
      @apply flex flex-col space-y-7;

      > div {
        @apply w-full;
      }
      p {
        @apply text-theme-secondary mb-1;
      }
    }

    .details {
      @apply pt-5;

      &.shipment-info {
        @apply pt-0;
      }
      .detail-item {
        @apply flex items-center py-1;

        span {
          @apply w-40;
        }

        p {
          @apply text-theme-secondary text-left;
        }
      }
    }
  }

  .inv-list {
    @apply mb-20 pt-2;
  }
  .inv-list {
    @media print {
      @apply px-10;
      page-break-inside: auto;
    }

    .inv-list-header {
      @apply flex items-center w-full px-10 py-4 border-b text-theme-body font-normal uppercase text-sm;
      border-color: rgba(180, 180, 180, 0.25);

      @media print {
        @apply px-0;
      }
    }
    .name,
    .info {
      @apply flex-grow;
    }

    .price,
    .qty,
    .tax {
      @apply w-24;
    }

    .total {
      @apply w-24;
    }

    .total-items {
      @apply w-36;

      @media print {
        font-size: 1rem;
      }
    }

    .inv-list-items {
      @apply w-full px-10 divide-y divide-gray-100;

      @media print {
        @apply px-0;
        page-break-inside: auto;
      }

      .inv-list-item {
        @apply flex items-center py-3 text-md text-theme-body font-normal;

        &:empty {
          @apply hidden;
        }

        @media print {
          @apply py-3;
          page-break-inside: avoid;
          page-break-after: auto;
        }

        .item-info {
          @apply flex items-center flex-grow;

          .item-check {
            @apply w-8 flex items-center justify-start flex-shrink-0;

            span {
              @apply w-3 h-3 rounded-sm border-2 border-gray-200 flex-shrink-0;
            }
          }

          .prod-info {
            @apply flex items-center space-x-4;
            img {
              @apply w-7 h-7;
            }

            .prod-name {
              @apply flex flex-col space-y-0.5 leading-none text-xs font-medium;

              p {
                @apply text-theme-secondary text-md;

                @media print {
                  font-size: 1rem;
                }
              }

              span {
                @apply text-theme-body text-sm;
              }
            }
          }
        }
        .total {
          @apply font-medium text-theme-dark;
        }
      }
    }
  }

  .inv-pricing {
    @apply flex flex-col w-full items-end py-5 px-10 mb-12 border-t;
    border-color: rgba(180, 180, 180, 0.25);

    .price-item {
      @apply inline-flex items-center justify-between flex-shrink-0 w-1/3 text-md text-theme-body font-light space-y-1.5;

      p {
        @apply w-32;
      }

      span {
        @apply w-24;
      }

      &.highlight {
        @apply text-theme-secondary font-medium py-2 border-t mt-2;
        border-color: rgba(180, 180, 180, 0.25);
      }

      &.extra {
        @apply font-medium text-sm;
        color: #8fa3a9;
      }
    }
  }

  .inv-footer {
    @apply w-full px-10 py-6 border-t  flex items-start justify-between text-sm font-light text-theme-body leading-normal;
    border-color: rgba(180, 180, 180, 0.25);

    span {
      @apply block;
    }
  }
}
</style>
